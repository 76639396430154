import { Table } from 'antd';
import moment from 'moment';
import React from 'react';

const CenterContractsView = ({ data }) => {
  const columns = [
    {
      title: 'Type of Contracts',
      dataIndex: 'type',
      key: 'name',
    },
    {
      title: 'Effective Date',
      dataIndex: 'effective_date_ts',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date_ts',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Auto Renewal',
      dataIndex: 'is_auto_renewal',
      render: (d) => {
        if (d) {
          return 'Yes'
        }
        return 'No'
      },
    },
    {
      title: 'Renewal Limit Date',
      dataIndex: 'renewal_limit_date',
      render: (d) => {
        if (d) {
          return <div>{moment(d * 1000).format('DD-MM-YYYY')}</div>
        }
        return <div> - </div>
      },
    },
    {
      title: 'Type of Center',
      dataIndex: 'is_parent',
      render: (text, record) => {
        return record.is_parent ? 'Parent Center' : 'Child Center'
      },
    },
  ];

  return (
    <div className="whole-deep-dive-page">
      <div className="CardList institution-dashboard">
        <div className="graph-section-results">
          <div className="graph-section-results-title">Type of Contracts:</div>
          <div className="graph-section-results-table">
            <Table
              columns={columns}
              dataSource={data?.data?.combined_contracts?.filter((i) => i.type)}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CenterContractsView
