import React, { useState } from 'react';
import {
  Button, DatePicker, Form, Radio, Select,
} from 'antd';
import moment from 'moment';
import { getCookie } from '../../../utils';

export default function CenterContracts({ data }) {
  const userId = getCookie('userName');
  const dateFormat = 'DD-MM-YYYY';
  const columns = [
    {
      label: 'Type of Contracts',
      key: 'type',
    },
    {
      label: 'Effective Date',
      key: 'effective_date_ts',
    },
    {
      label: 'Expiry Date',
      key: 'expiry_date_ts',
    },
    {
      label: 'Auto Renewal',
      key: 'is_auto_renewal',
    },
    {
      label: 'Renewal Limit Date',
      key: 'renewal_limit_date',
    },
    {
      label: '',
      key: 'action',
    },
  ];
  const [newContract, setNewContract] = useState({
    type: '',
    effective_date_ts: '',
    expiry_date_ts: undefined,
    is_auto_renewal: undefined,
    renewal_limit_date: undefined,
  });
  const [editContract, setEditContract] = useState({
    type: '',
    effective_date_ts: '',
    expiry_date_ts: undefined,
    is_auto_renewal: undefined,
    renewal_limit_date: undefined,
  });
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
  });

  const [newContractDateErrorMsg, setNewContractDateErrorMsg] = useState({
    effective_date: '',
    expiry_date: '',
  })

  const [editContractDateErrorMsg, setEditContractDateErrorMsg] = useState({
    effective_date: '',
    expiry_date: '',
  })

  const contracts_type = [
    {
      label: 'Site Alliance General Business Agreement',
      key: 'Site Alliance General Business Agreement',
      value: 'Site Alliance General Business Agreement',
    },
    {
      label: 'Master Confidentiality Disclosure Agreement',
      key: 'Master Confidentiality Disclosure Agreement',
      value: 'Master Confidentiality Disclosure Agreement',
    },
    {
      label: 'Master Service Agreement',
      key: 'Master Service Agreement',
      value: 'Master Service Agreement',
    },
    {
      label: 'Master Clinical Trial/Study Agreement',
      key: 'Master Clinical Trial/Study Agreement',
      value: 'Master Clinical Trial/Study Agreement',
    },
  ];

  const handleAddNewContract = () => {
    data.setContracts([
      ...data.contracts,
      {
        type: newContract.type ? newContract.type : undefined,
        effective_date_ts: newContract.effective_date_ts
          ? moment(newContract.effective_date_ts).unix()
          : undefined,
        expiry_date_ts: newContract.expiry_date_ts
          ? moment(newContract.expiry_date_ts).unix()
          : undefined,
        is_auto_renewal: newContract.is_auto_renewal === 'Yes' ? true : newContract.is_auto_renewal === 'No' ? false : undefined,
        renewal_limit_date: newContract.renewal_limit_date
          ? moment(newContract.renewal_limit_date).unix()
          : undefined,
      },
    ]);
    setNewContract({
      type: '',
      effective_date_ts: '',
      expiry_date_ts: undefined,
      is_auto_renewal: undefined,
      renewal_limit_date: undefined,
    });
  };

  const onSaveEditClick = () => {
    const temp = data.contracts;
    const obj = {
      type: editContract.type ? editContract.type : undefined,
      effective_date_ts: editContract.effective_date_ts
        ? Number.isInteger(editContract.effective_date_ts)
          ? editContract.effective_date_ts
          : moment(editContract.effective_date_ts).unix()
        : undefined,
      expiry_date_ts: editContract.expiry_date_ts
        ? Number.isInteger(editContract.expiry_date_ts)
          ? editContract.expiry_date_ts
          : moment(editContract.expiry_date_ts).unix()
        : undefined,
      is_auto_renewal:
                editContract.is_auto_renewal === true || editContract.is_auto_renewal === 'Yes'
                  ? true : editContract.is_auto_renewal === false || editContract.is_auto_renewal === 'No' ? false : undefined,
      renewal_limit_date: editContract.renewal_limit_date
        ? Number.isInteger(editContract.renewal_limit_date)
          ? editContract.renewal_limit_date
          : moment(editContract.renewal_limit_date).unix()
        : undefined,
    };
    temp[editing.editingId] = obj;
    setEditContract(temp);
    setEditing({ isEditing: false, editingId: '' });
  };

  const handleContractDelete = (index) => {
    const temp = data.contracts;
    temp.splice(index, 1);

    data.setContracts([...temp]);
  };
  return (
    <div
      className={
                (userId === data.checkUserId && data.checkStatus === 'Send_for_clarification')
                    || data.flag !== 'admin'
                  ? 'contracts-card-wrapper'
                  : 'contracts-card-wrapper disable-pi'
            }
    >
      <Form
        layout="vertical"
        name="piform"
        className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') || data.flag !== 'admin'
          ? null
          : 'disable-pi-form'}
      >
        <div className="license-card">
          <div className="license-table-main">
            <div className="license-table-header">
              {columns.map((type) => (
                <div className="license-table-header-item">{type.label}</div>
              ))}
            </div>
            {data.flag !== 'edit-admin' && (
            <div className="license-table-body">
              {data?.contracts?.map((record, index) => {
                return editing.isEditing && editing.editingId === index ? (
                  <>
                    <div className="license-table-row">
                      <div className="license-table-row-itm">
                        <Select
                          options={contracts_type}
                          value={editContract.type}
                          onChange={(e) => setEditContract({ ...editContract, type: e })}
                        />
                      </div>
                      {/* <div className="license-table-row-itm">
                        <DatePicker
                          value={editContract.effective_date_ts}
                          format={dateFormat}
                          onChange={(e) => setEditContract({ ...editContract, effective_date_ts: e })}
                        />
                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker
                          value={editContract.expiry_date_ts}
                          format={dateFormat}
                          onChange={(e) => setEditContract({ ...editContract, expiry_date_ts: e })}
                        />
                      </div> */}
                      <div className="license-table-row-itm">
                        <DatePicker
                          value={editContract.effective_date_ts}
                          format={dateFormat}
                          onChange={(e) => {
                            if (e && editContract.expiry_date_ts && e.isAfter(editContract.expiry_date_ts)) {
                              setEditContractDateErrorMsg((prev) => ({
                                ...prev,
                                effective_date: 'Effective date must be earlier than the expiry date!',
                              }));
                              setEditContract({ ...editContract, effective_date_ts: e });
                            } else {
                              setEditContract({ ...editContract, effective_date_ts: e });
                              setEditContractDateErrorMsg((prev) => ({
                                ...prev,
                                effective_date: '',
                              }));
                            }
                          }}
                        />
                        {
                        editContractDateErrorMsg.effective_date ? <div style={{ color: 'red' }}>{editContractDateErrorMsg.effective_date} </div> : ''
                    }

                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker
                          value={editContract.expiry_date_ts}
                          format={dateFormat}
                          onChange={(e) => {
                            if (e && editContract.effective_date_ts && e.isBefore(editContract.effective_date_ts)) {
                              setEditContractDateErrorMsg((prev) => ({
                                ...prev,
                                expiry_date: 'Expiry date must be later than the effective date!',
                              }));
                              setEditContract({ ...editContract, expiry_date_ts: e });
                            } else {
                              setEditContractDateErrorMsg((prev) => ({
                                ...prev,
                                expiry_date: '',
                              }))
                              setEditContract({ ...editContract, expiry_date_ts: e });
                            }
                          }}
                        />
                        {
                        editContractDateErrorMsg.expiry_date ? <div style={{ color: 'red' }}>{editContractDateErrorMsg.expiry_date} </div> : ''
                    }
                      </div>
                      <div className="license-table-row-itm">
                        <Radio.Group
                          value={editContract.is_auto_renewal === true || editContract.is_auto_renewal === 'Yes'
                            ? 'Yes'
                            : 'No'}
                          onChange={(e) => {
                            setEditContract({
                              ...editContract,
                              is_auto_renewal: e.target.value,
                              renewal_limit_date: e.target.value === 'Yes' && editContract.renewal_limit_date
                                ? null
                                : editContract.renewal_limit_date,
                            });
                          }}
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker
                          value={editContract.renewal_limit_date}
                          disabled={!(
                            editContract.is_auto_renewal === 'No' || editContract.is_auto_renewal === false
                          )}
                          format={dateFormat}
                          onChange={(e) => setEditContract({ ...editContract, renewal_limit_date: e })}
                        />
                      </div>
                      <div className="license-table-row-itm">
                        <Button
                          disabled={editContract.type === '' || editContract.effective_date_ts === null || editContract.effective_date_ts === undefined || editContract.effective_date_ts === '' || editContractDateErrorMsg.effective_date !== '' || editContractDateErrorMsg.expiry_date !== ''}
                          onClick={() => onSaveEditClick()}
                          className="save-btn"
                          title="Save"
                        />
                        <Button
                          onClick={() => {
                            setEditContract({
                              country_type: '',
                              region: '',
                              country: '',
                              sites_in_scenario: undefined,
                              average_patients_or_sites: undefined,
                              sfl_target_sites: undefined,
                              cfl_target: undefined,
                            });
                            setEditing({ isEditing: false, editingId: '' });
                          }}
                          className="cancel-btn"
                          title="Cancel"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="license-table-row">
                    <div className="license-table-row-itm">
                      {record.type ? record.type : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.effective_date_ts
                        ? new Date(record.effective_date_ts * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.expiry_date_ts
                        ? new Date(record.expiry_date_ts * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.is_auto_renewal === false
                        ? 'No'
                        : record.is_auto_renewal === true
                          ? 'Yes'
                          : record.is_auto_renewal === 'Yes'
                            ? 'Yes'
                            : record.is_auto_renewal === 'No'
                              ? 'No'
                              : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.renewal_limit_date
                        ? new Date(record.renewal_limit_date * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      <Button
                        onClick={() => {
                          handleContractDelete(index);
                        }}
                        className="delete-btn"
                      />
                      <Button
                        onClick={() => {
                          setEditContract({
                            type: record.type,
                            effective_date_ts: record.effective_date_ts
                              ? moment(record.effective_date_ts * 1000)
                              : undefined,
                            expiry_date_ts: record.expiry_date_ts
                              ? moment(record.expiry_date_ts * 1000)
                              : undefined,
                            is_auto_renewal: record.is_auto_renewal
                              ? record.is_auto_renewal
                              : undefined,
                            renewal_limit_date: record.renewal_limit_date
                              ? moment(record.renewal_limit_date * 1000)
                              : undefined,
                          });
                          setEditing({ isEditing: true, editingId: index });
                        }}
                        className="edit-btn"
                        title="Edit"
                      />
                    </div>
                  </div>
                );
              })}
              {!editing.isEditing && (
              <>
                <div className="license-table-row">
                  <div className="license-table-row-itm">
                    <Select
                      value={newContract.type}
                      options={contracts_type}
                      onChange={(e) => setNewContract({ ...newContract, type: e })}
                    />
                  </div>
                  {/* <div className="license-table-row-itm">
                    <DatePicker
                      value={newContract.effective_date_ts}
                      format={dateFormat}
                      onChange={(e) => setNewContract({ ...newContract, effective_date_ts: e })}
                    />
                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker
                      value={newContract.expiry_date_ts}
                      format={dateFormat}
                      onChange={(e) => setNewContract({ ...newContract, expiry_date_ts: e })}
                    />
                  </div> */}
                  <div className="license-table-row-itm">
                    <DatePicker
                      value={newContract.effective_date_ts}
                      format={dateFormat}
                      onChange={(e) => {
                        if (e && newContract.expiry_date_ts && e.isAfter(newContract.expiry_date_ts)) {
                          setNewContractDateErrorMsg((prev) => ({
                            ...prev,
                            effective_date: 'Effective date must be earlier than the expiry date!',
                          }));
                          setNewContract({ ...newContract, effective_date_ts: e });
                        } else {
                          setNewContract({ ...newContract, effective_date_ts: e });
                          setNewContractDateErrorMsg((prev) => ({
                            ...prev,
                            effective_date: '',
                          }));
                        }
                      }}
                    />
                    {
                        newContractDateErrorMsg.effective_date ? <div style={{ color: 'red' }}>{newContractDateErrorMsg.effective_date} </div> : ''
                    }

                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker
                      value={newContract.expiry_date_ts}
                      format={dateFormat}
                      onChange={(e) => {
                        if (e && newContract.effective_date_ts && e.isBefore(newContract.effective_date_ts)) {
                          setNewContractDateErrorMsg((prev) => ({
                            ...prev,
                            expiry_date: 'Expiry date must be later than the effective date!',
                          }));
                          setNewContract({ ...newContract, expiry_date_ts: e });
                        } else {
                          setNewContractDateErrorMsg((prev) => ({
                            ...prev,
                            expiry_date: '',
                          }))
                          setNewContract({ ...newContract, expiry_date_ts: e });
                        }
                      }}
                    />
                    {
                        newContractDateErrorMsg.expiry_date ? <div style={{ color: 'red' }}>{newContractDateErrorMsg.expiry_date} </div> : ''
                    }
                  </div>

                  <div className="license-table-row-itm">
                    <Radio.Group
                      value={newContract.is_auto_renewal}
                      onChange={(e) => {
                        setNewContract({
                          ...newContract,
                          is_auto_renewal: e.target.value,
                          renewal_limit_date: e.target.value === 'No' && newContract.renewal_limit_date
                            ? undefined
                            : newContract.renewal_limit_date,
                        });
                      }}
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker
                      value={newContract.renewal_limit_date}
                      disabled={!(
                        newContract.is_auto_renewal === 'No' || newContract.is_auto_renewal === false
                      )}
                      format={dateFormat}
                      onChange={(e) => setNewContract({ ...newContract, renewal_limit_date: e })}
                    />
                  </div>
                  <div className="license-table-row-itm">
                    <Button
                      disabled={newContract.type === '' || newContract.effective_date_ts === null || newContract.effective_date_ts === undefined || newContract.effective_date_ts === '' || newContractDateErrorMsg.effective_date !== '' || newContractDateErrorMsg.expiry_date !== ''}
                      onClick={() => {
                        if (newContract.type !== '') {
                          handleAddNewContract();
                        }
                      }}
                      className="save-btn"
                    />
                    <Button
                      disabled={newContract.type === ''}
                      onClick={() => {
                        setNewContract({
                          type: '',
                          effective_date_ts: '',
                          expiry_date_ts: undefined,
                          is_auto_renewal: undefined,
                          renewal_limit_date: undefined,
                        });
                      }}
                      className="cancel-btn"
                    />
                  </div>
                </div>
              </>
              )}
            </div>
            )}
            {data.flag === 'edit-admin' && (
            <div className="license-table-body disabled">
              {data?.contracts?.map((record, index) => {
                return editing.isEditing && editing.editingId === index ? (
                  <>
                    <div className="license-table-row">
                      <div className="license-table-row-itm">
                        <Select disabled />
                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker disabled />
                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker disabled />
                      </div>
                      <div className="license-table-row-itm">
                        <Radio.Group disabled>
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </div>
                      <div className="license-table-row-itm">
                        <DatePicker disabled />
                      </div>
                      <div className="license-table-row-itm">
                        <Button
                          className="save-btn"
                          title="Save"
                        />
                        <Button
                          className="cancel-btn"
                          title="Cancel"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="license-table-row">
                    <div className="license-table-row-itm">
                      {record.type ? record.type : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.effective_date_ts
                        ? new Date(record.effective_date_ts * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.expiry_date_ts
                        ? new Date(record.expiry_date_ts * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.is_auto_renewal === false
                        ? 'No'
                        : record.is_auto_renewal === true
                          ? 'Yes'
                          : record.is_auto_renewal === 'Yes'
                            ? 'Yes'
                            : record.is_auto_renewal === 'No'
                              ? 'No'
                              : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      {record.renewal_limit_date
                        ? new Date(record.renewal_limit_date * 1000).toLocaleDateString()
                        : '-'}
                    </div>
                    <div className="license-table-row-itm">
                      <Button
                        disabled
                        className="delete-btn"
                      />
                      <Button
                        className="edit-btn"
                        title="Edit"
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
              {!editing.isEditing && (
              <>
                <div className="license-table-row">
                  <div className="license-table-row-itm">
                    <Select disabled />
                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker disabled />
                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker disabled />
                  </div>

                  <div className="license-table-row-itm">
                    <Radio.Group disabled>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </div>
                  <div className="license-table-row-itm">
                    <DatePicker disabled />
                  </div>
                  <div className="license-table-row-itm">
                    <Button
                      className="save-btn"
                      disabled
                    />
                    <Button
                      className="cancel-btn"
                      disabled
                    />
                  </div>
                </div>
              </>
              )}
            </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
