import { Table } from 'antd'
import React from 'react'

export default function CenterCompliance({ complianceData }) {
  const columns = [
    {
      title: 'Compliance ID',
      dataIndex: 'unique_id',
      render: (text, record) => {
        return record.unique_id ? record.unique_id : '-'
      },
    },
    {
      title: 'Compliance Preventing Participation',
      dataIndex: 'no_trial_participation_flag',
      render: (text, record) => {
        return record.no_trial_participation_flag === 'N' ? 'No' : record.no_trial_participation_flag === 'Y' ? 'Yes' : record.no_trial_participation_flag === 'NO' ? 'No' : record.no_trial_participation_flag === 'YES' ? 'Yes' : record.no_trial_participation_flag
      },
    },
    {
      title: 'Compliance Issue Source',
      dataIndex: 'compliance_issue_source',
      render: (text, record) => {
        return <div style={{ whiteSpace: 'pre-line' }}>{record.compliance_issue_source ? record.compliance_issue_source : '-'}</div>
      },
    },
    {
      title: 'Compliance Issue Comment',
      dataIndex: 'compliance_issue_comment',
      render: (text, record) => {
        return <div style={{ whiteSpace: 'pre-line' }}>{record.compliance_issue_comment ? record.compliance_issue_comment : '-'}</div>
      },
    },
    {
      title: 'Compliance Issue Description',
      dataIndex: 'compliance_issue_desc',
      render: (text, record) => {
        return record.compliance_issue_desc ? record.compliance_issue_desc : '-'
      },
    },
    {
      title: 'Compliance Issue Raised',
      dataIndex: 'compliance_issue_raised_dt',
      render: (text, record) => {
        return record.compliance_issue_raised_dt && record.compliance_issue_raised_dt.length ? record.compliance_issue_raised_dt.split(' ')[0] : '-'
      },
    },
    {
      title: 'Reviewed Status',
      dataIndex: 'review_status',
      render: (text, record) => {
        return record.review_status ? record.review_status : '-'
      },
    },
    {
      title: 'Review Date',
      dataIndex: 'review_date',
      render: (text, record) => {
        return record.review_date && record.review_date.length ? record.review_date.split(' ')[0] : '-'
      },
    },
    {
      title: 'Reviewer Name',
      dataIndex: 'reviewer_name',
      render: (text, record) => {
        return record.reviewer_name ? record.reviewer_name : '-'
      },
    },
    {
      title: 'Re-evaluation Comment',
      dataIndex: 'reevaluation_comment',
      render: (text, record) => {
        return record.reevaluation_comment ? record.reevaluation_comment : '-'
      },
    },
    {
      title: 'Type of Center',
      dataIndex: 'is_parent',
      render: (text, record) => {
        return record.is_parent ? 'Parent Center' : 'Child Center'
      },
    },
  ];

  const cddcolumns = [
    {
      title: 'CDD Status',
      dataIndex: 'status',
      render: (text, record) => {
        return record.status ? record.status : '-'
      },
    },
    {
      title: 'CDD Date',
      dataIndex: 'date',
      render: (text, record) => {
        return record.date ? record.date : '-'
      },
    },
    {
      title: 'CDD Comment',
      dataIndex: 'comment',
      render: (text, record) => {
        return <div style={{ whiteSpace: 'pre-line' }}>{record.comment ? record.comment : '-'}</div>
      },
    },
    {
      title: 'Type of Center',
      dataIndex: 'is_parent',
      render: (text, record) => {
        return record.is_parent ? 'Parent Center' : 'Child Center'
      },
    },
  ]
  return (
    <div className="qualitymatrices-tabledata">
      <div className="card PI pi-inpage study-wrapper">
        <div className="content-table">
          <div className="studies-name-wrapper">Site Issue (GCP/ Quality / Operational) & CDD of Parent Institution and Center:</div>
          <Table
            columns={cddcolumns}
            dataSource={complianceData?.data?.combined_cdd}
            pagination={false}
            style={{ whiteSpace: 'pre-line', paddingBottom: '50px' }}
          />
          <Table
            columns={columns}
            dataSource={complianceData?.data?.combined_compliances}
            pagination={false}
            style={{ whiteSpace: 'pre-line' }}
          />
        </div>
      </div>
    </div>
  )
}
